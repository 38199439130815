import React from "react";
import { Tab, PseudoBoxProps, Box } from "@chakra-ui/core";
import { FieldI } from "../../types";

import CreateForm from "../fluxField/CreateForm";
import AdaptiveInput from "../../components/lib/AdaptiveInput";
type TabProps = PseudoBoxProps & React.ButtonHTMLAttributes<any>;

interface FieldTabPanelProps {
  tableID: string;
  baseID: string;
  fields: FieldI[];
  onRefreshTable?: () => void;
}

const FieldTab: React.ForwardRefRenderFunction<React.PropsWithChildren<
  TabProps
>> = (props, ref) => {
  return (
    <Tab
      borderWidth="1px"
      rounded="md"
      m={3}
      _selected={{
        borderColor: "gray.500",
        bg: "gray.100",
      }}
      ref={ref}
      {...props}
    >
      Fields
    </Tab>
  );
};
const FieldTabPanel: React.FC<FieldTabPanelProps> = ({
  tableID,
  baseID,
  fields,
  onRefreshTable,
}) => {
  return (
    <Box>
      <CreateForm
        tableID={tableID}
        baseID={baseID}
        afterSubmit={onRefreshTable}
      />

      {fields.map((field: FieldI) => {
        return (
          <AdaptiveInput
            key={field.id}
            field={field}
            onRefreshTable={onRefreshTable}
          />
        );
      })}
    </Box>
  );
};

export default {
  Tab: React.forwardRef(FieldTab),
  Panel: FieldTabPanel,
};
