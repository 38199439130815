import React, { useState, useEffect } from "react";

import { Text, Button, Input } from "@chakra-ui/core";

import { Formik } from "formik";
import { ColorField, IconField } from "../../constants";
import { getAction, editAction } from "../../api";

interface EditFormProps {
  actionID: string;
  afterSubmit?: () => void;
}

export const EditForm: React.FC<EditFormProps> = ({
  actionID,
  afterSubmit,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [color, setColor] = useState("red");
  const [icon, setIcon] = useState("textfile");
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!!actionID) {
      getAction(actionID).then((result) => {
        setInitialValues({ name: result.name, slug: result.slug });
        setReady(true);
      });
    }
  }, [actionID, setReady]);
  if (!ready) {
    return null;
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if ((values as any).name === "") {
          (errors as any).name = "Required";
        }
        if ((values as any).slug === "") {
          (errors as any).slug = "Required";
        }
        return {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        editAction(actionID, { ...values, meta: { color, icon } }).then(() => {
          !!afterSubmit && afterSubmit();
          setSubmitting(false);
        });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Text>Name</Text>
          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={(values as any).name}
            value={(values as any).name}
          />
          <Text>Slug</Text>
          <Input
            type="text"
            name="slug"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).slug}
          />
          <IconField
            icons={[
              "calendar",
              "time",
              "star",
              "bell",
              "phone",
              "smile",
              "meh",
              "frown",
            ]}
            activeIcon={icon}
            setActiveIcon={setIcon}
          />
          <ColorField
            colors={[
              "red",
              "green",
              "blue",
              "yellow",
              "orange",
              "purple",
              "teal",
            ]}
            activeColor={color}
            setActiveColor={setColor}
          />
          <Button type="submit">Save</Button>
        </form>
      )}
    </Formik>
  );
};

export default EditForm;
