import React, { useState, useEffect } from "react";

import {
  Text,
  Button,
  Input,
  Box,
  Icon,
  Collapse,
  Select,
  PseudoBox,
} from "@chakra-ui/core";

import { Formik } from "formik";
import { flux } from "oddular-common";
import { createField } from "../../api";

interface CreateFormProps {
  tableID: string;
  baseID: string;
  afterSubmit?: () => void;
}

export const CreateForm: React.FC<CreateFormProps> = ({
  tableID,
  baseID,
  afterSubmit,
}) => {
  const [open, setOpen] = useState(false);
  const [isForeignKey, setIsForeignKey] = useState(false);
  const [tables, setTables] = useState([]);
  useEffect(() => {
    if (isForeignKey) {
      flux.base.tables(baseID).then((tables) => {
        setTables(tables);
      });
    }
  }, [isForeignKey, setTables, baseID]);
  return (
    <Box m={2}>
      <PseudoBox
        onClick={() => setOpen(!open)}
        w="full"
        display="flex"
        justifyContent="space-between"
        _hover={{ bg: "gray.100" }}
        _active={{ bg: "gray.100" }}
        roundedTop="lg"
        roundedBottom={open ? "none" : "lg"}
        bg={open ? "gray.100" : "white"}
        px={8}
        py={5}
      >
        <Text fontWeight="1000" color="green.500">
          Add Field
        </Text>
        <Box>
          <Icon
            name="add"
            transform={open ? "rotate(45deg)" : ""}
            transition="0.2s ease-in-out"
            color="green.500"
          ></Icon>
        </Box>
      </PseudoBox>
      <Collapse isOpen={open}>
        <Box bg="gray.100" roundedBottom="lg" px={10} py={8}>
          <Formik
            initialValues={{
              name: "",
              field_type: "",
              linked_record_table: "",
            }}
            validate={(values) => {
              const errors = {};
              if ((values as any).name === "") {
                (errors as any).name = "Required";
              }
              if ((values as any).field_type === "") {
                (errors as any).field_type = "Required";
              }
              return {};
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (values.field_type === "linked_record") {
                if (values.linked_record_table !== "") {
                  createField(
                    tableID,
                    values.name,
                    "",
                    values.field_type,
                    values.linked_record_table
                  ).then((data) => {
                    setSubmitting(false);
                    !!afterSubmit && afterSubmit();
                  });
                }
              } else {
                createField(tableID, values.name, "", values.field_type).then(
                  (data) => {
                    setSubmitting(false);
                    !!afterSubmit && afterSubmit();
                  }
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Text>Field Type</Text>
                <Select
                  name="field_type"
                  onChange={(v) => {
                    setIsForeignKey(v.currentTarget.value === "linked_record");
                    handleChange(v);
                  }}
                  onBlur={handleBlur}
                  value={(values as any).field_type}
                >
                  <option value="">---</option>
                  {Object.entries(flux.field.FIELD_TYPES).map(
                    ([field, value]: [string, string | any]) => {
                      return (
                        <option key={field} value={field}>
                          {value}
                        </option>
                      );
                    }
                  )}
                </Select>
                {isForeignKey ? (
                  <Select
                    name="linked_record_table"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Select Table"
                  >
                    {!!tables &&
                      tables.length > 0 &&
                      (tables as any).map((t: any) => {
                        return <option value={t.id}>{t.name}</option>;
                      })}
                  </Select>
                ) : null}
                <Text>Name</Text>
                <Input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={(values as any).name}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  my={5}
                >
                  <Button type="submit" variantColor="blue">
                    Add Field
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CreateForm;
