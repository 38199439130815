import React, { useRef } from "react";

import { Button } from "@chakra-ui/core";
import { Formik } from "formik";
import { FieldI } from "../../types";
import AdaptiveRecordInput from "../lib/AdaptiveRecordInput";
import { OddModal } from "oddular-common";
import { createData } from "../../api";

interface CreateRecordModalProps {
  isOpen: boolean;
  onClose: () => void;
  baseID: string;
  initialData: any;
  tableID: string;
  fields: FieldI[];
}

export const CreateRecordModal: React.FC<CreateRecordModalProps> = ({
  isOpen,
  onClose,
  baseID,
  initialData,
  tableID,
  fields,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleFormSubmit = () => {
    if (formRef !== null) {
      formRef.current!.handleSubmit();
    }
  };
  return (
    <OddModal
      context="default"
      header="Video"
      children={
        <div>
          <Formik
            innerRef={formRef as any}
            initialValues={initialData}
            validate={() => {
              return {};
            }}
            onSubmit={(values, { setSubmitting }) => {
              createData(tableID, values).then(() => {
                onClose();
              });
              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                {fields.map((field) => {
                  const v = values[field.slug];
                  return (
                    <AdaptiveRecordInput
                      key={field.id}
                      field={field}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={v}
                    />
                  );
                })}
              </form>
            )}
          </Formik>
        </div>
      }
      footer={[
        <Button key={0} size="sm" variantColor="gray" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={1}
          size="sm"
          onClick={handleFormSubmit}
          variantColor="blue"
        >
          Create
        </Button>,
      ]}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default CreateRecordModal;
