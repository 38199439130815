import React from "react";
import { Tab, PseudoBoxProps, Box, Text } from "@chakra-ui/core";
import { FieldI, ColumnI } from "../../types";

type TabProps = PseudoBoxProps & React.ButtonHTMLAttributes<any>;

interface JsonTabPanelProps {
  columns: ColumnI[];
  data: any;
  fields: FieldI[];
}

const JsonTab: React.ForwardRefRenderFunction<React.PropsWithChildren<
  TabProps
>> = (props, ref) => {
  return (
    <Tab
      borderWidth="1px"
      rounded="md"
      m={3}
      _selected={{
        borderColor: "gray.500",
        bg: "gray.100",
      }}
      ref={ref}
      {...props}
    >
      JSON
    </Tab>
  );
};
const JsonTabPanel: React.FC<JsonTabPanelProps> = ({
  columns,
  data,
  fields,
}) => {
  return (
    <Box>
      <Text fontWeight="1000" color="purple.500">
        Columns
      </Text>
      {JSON.stringify(columns)}
      <Text fontWeight="1000" color="purple.500">
        Data
      </Text>
      {JSON.stringify(data)}
      <Text fontWeight="1000" color="purple.500">
        Fields
      </Text>
      {JSON.stringify(fields)}
    </Box>
  );
};

export default {
  Tab: React.forwardRef(JsonTab),
  Panel: JsonTabPanel,
};
