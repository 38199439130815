import React, { useEffect, useState } from "react";

import { Text, Box, Image, Link, Icon, AspectRatioBox } from "@chakra-ui/core";

import matter, { GrayMatterFile } from "gray-matter";
import MarkdownRenderer from "./MarkdownRenderer";
import ReactMarkdown from "react-markdown";
import { flux } from "oddular-common";
import { Editable } from "./Editable";
import { IRecord, FieldI, TableI } from "../../types";

interface AdaptiveFieldProps {
  field: FieldI;
  value: any;
  setImage: (image: string) => void;
  setVideo: (video: string) => void;
  recordID: string;
}

export const AdaptiveField: React.FC<AdaptiveFieldProps> = ({
  field,
  value,
  setImage,
  setVideo,
  recordID,
}) => {
  const [table, setTable] = useState({} as TableI);
  const [record, setRecord] = useState({} as IRecord);
  useEffect(() => {
    if (field.field_type === "linked_record") {
      flux.table.detail(field.meta.linked_record_table).then((result) => {
        setTable(result);
      });
      //get LINKED record
      flux.record.detail(value).then((result) => {
        setRecord(result);
      });
    }
  }, [setTable, field, setRecord, value]);
  const updateRecord = (value: any) => {
    const values = {};
    (values as any)[field.slug] = value;
    flux.record.update(recordID, values);
  };
  if (!value) {
    return <Editable value="undefined" setValue={updateRecord} />;
  }
  switch (field.field_type) {
    case "url":
      return (
        <Link href={value}>
          <Editable value={value} setValue={updateRecord} />
        </Link>
      );
    case "number":
      return <Editable value={value} setValue={updateRecord} />;
    case "multi_line_text":
      return <Editable value={value} setValue={updateRecord} />;
    case "markdown":
      const md: GrayMatterFile<any> = matter(value);
      return (
        <ReactMarkdown source={md.content} renderers={MarkdownRenderer()} />
      );
    case "datetime":
      return <Editable value={value} setValue={updateRecord} />;
    case "phone_number":
      return (
        <Text>
          <Editable value={value} setValue={updateRecord} />;
          <Link href={"tel:" + value}>
            <Icon name="phone" m={1} />
          </Link>
          <Link href={"sms:" + value}>
            <Icon name="chat" m={1} />
          </Link>
        </Text>
      );
    case "email":
      return (
        <Link href={"mailto:" + value}>
          <Editable value={value} setValue={updateRecord} />;
          <Icon name="email" m={1} />
        </Link>
      );
    case "single_line_text":
      return <Editable value={value} setValue={updateRecord} />;
    case "image":
      return <Image onClick={() => setImage(value)} src={value} alt={value} />;
    case "video":
      return (
        <Box display="flex">
          <AspectRatioBox ratio={16 / 9}>
            <iframe title="video" src={value} />
          </AspectRatioBox>
          <Box
            w="full"
            ml="-100%"
            zIndex={2}
            onClick={() => setVideo(value)}
          ></Box>
        </Box>
      );
    case "document":
      return (
        <Link href={value}>
          <Icon name="textfile" m={1} />
        </Link>
      );
    case "file":
      return (
        <Link href={value}>
          <Icon name="attachment" m={1} />
        </Link>
      );
    case "linked_record":
      return (
        <Text>
          {!!record &&
            !!record.values &&
            !!table &&
            !!table.meta &&
            record.values[table.meta.linked_record_field]}
        </Text>
      );
    default:
      return <Editable value={value} setValue={updateRecord} />;
  }
};

export default AdaptiveField;
