import React, { useState } from "react";
import { Tab, PseudoBoxProps, Box, Button } from "@chakra-ui/core";

import { Form } from "../../components/forms/form";
import { FieldI } from "../../types";
import { SideDrawer } from "../../components/lib/SideDrawer";
import { ColorPicker } from "../../components/lib/ColorPicker";
type TabProps = PseudoBoxProps & React.ButtonHTMLAttributes<any>;

interface FormTabPanelProps {
  tableID: string;
  fields: FieldI[];
  onRefreshTable?: () => void;
}

const FormTab: React.ForwardRefRenderFunction<React.PropsWithChildren<
  TabProps
>> = (props, ref) => {
  return (
    <Tab
      borderWidth="1px"
      rounded="md"
      m={3}
      _selected={{
        borderColor: "gray.500",
        bg: "gray.100",
      }}
      ref={ref}
      {...props}
    >
      Forms
    </Tab>
  );
};
const FormTabPanel: React.FC<FormTabPanelProps> = ({ fields }) => {
  const [open, setOpen] = useState(false);
  const btnRef = React.useRef() as React.RefObject<HTMLElement>;
  const [color, setColor] = useState("#c0e0d0");
  return (
    <Box>
      <Button ref={btnRef} onClick={() => setOpen(true)}>
        Settings
      </Button>
      <Form
        title={`Create a Record for this Table`}
        description="My Description"
        fields={fields}
        backgroundColor={color}
      />
      <SideDrawer
        open={open}
        onClose={() => setOpen(false)}
        finalFocusRef={btnRef}
        content={
          <Box display="flex" justifyContent="center">
            <ColorPicker initialColor={color} exportColor={setColor} />
          </Box>
        }
        header=""
        footer=""
      />
    </Box>
  );
};

export default {
  Tab: React.forwardRef(FormTab),
  Panel: FormTabPanel,
};
