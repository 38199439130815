import React, { useRef } from "react";
import DeleteForm from "./DeleteForm";
import { OddModal } from "oddular-common";
import { Button } from "@chakra-ui/core";

interface DeleteModalProps {
  tableID: string;
  baseID: string;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  tableID,
  baseID,
  isOpen,
  onClose,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleFormSubmit = () => {
    if (formRef !== null) {
      formRef.current!.handleSubmit();
    }
  };
  return (
    <OddModal
      context="default"
      header="Delete Table"
      children={
        <DeleteForm
          formRef={formRef}
          afterSubmit={() => {
            onClose();
            window.location.replace("/base/" + baseID);
          }}
          tableID={tableID}
        />
      }
      footer={[
        <Button key={0} size="sm" variantColor="gray" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={1}
          size="sm"
          onClick={handleFormSubmit}
          variantColor="blue"
        >
          Delete
        </Button>,
      ]}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default DeleteModal;
