import React, { useState, useEffect, RefObject } from "react";

import { Text, Input, Select } from "@chakra-ui/core";

import { Formik } from "formik";
import { ColorField, IconField } from "../../constants";
import { getTable, editTable } from "../../api";
import { FieldI } from "../../types";

interface EditFormProps {
  tableID: string;
  formRef: RefObject<HTMLFormElement> | null;
  afterSubmit?: () => void;
}

export const EditForm: React.FC<EditFormProps> = ({
  tableID,
  formRef,
  afterSubmit,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [color, setColor] = useState("red");
  const [icon, setIcon] = useState("textfile");
  const [ready, setReady] = useState(false);
  const [fields, setFields] = useState([] as FieldI[]);
  useEffect(() => {
    if (!!tableID) {
      getTable(tableID).then((result) => {
        setFields(result.fields);
        setInitialValues({
          name: result.name,
          slug: result.slug,
          description: result.description,
          linked_record_field: "id",
        });
        setReady(true);
      });
    }
  }, [tableID, setReady, setFields]);
  if (!ready) {
    return null;
  }
  return (
    <Formik
      innerRef={formRef as any}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if ((values as any).name === "") {
          (errors as any).name = "Required";
        }
        if ((values as any).slug === "") {
          (errors as any).slug = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        editTable(tableID, {
          name: (values as any).name,
          slug: (values as any).slug,
          meta: {
            color,
            icon,
            description: (values as any).description,
            linked_record_field: (values as any).linked_record_field,
          },
        }).then((data) => {
          !!afterSubmit && afterSubmit();
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Text>Name</Text>
          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).name}
          />
          <Text>Slug</Text>
          <Input
            type="text"
            name="slug"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).slug}
          />
          <IconField
            icons={[
              "calendar",
              "time",
              "star",
              "bell",
              "phone",
              "smile",
              "meh",
              "frown",
            ]}
            activeIcon={icon}
            setActiveIcon={setIcon}
          />
          <ColorField
            colors={[
              "red",
              "green",
              "blue",
              "yellow",
              "orange",
              "purple",
              "teal",
            ]}
            activeColor={color}
            setActiveColor={setColor}
          />
          <Text>Description</Text>
          <Input
            type="text"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).description}
          />
          <Text>Linked Record Field</Text>
          <Select
            name="linked_record_field"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Select Field"
          >
            {!!fields &&
              fields.length > 0 &&
              (fields as any).map((f: any) => {
                return <option value={f.slug}>{f.name}</option>;
              })}
          </Select>
        </form>
      )}
    </Formik>
  );
};

export default EditForm;
