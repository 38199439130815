import React, { useState, RefObject } from "react";

import { Text, Input } from "@chakra-ui/core";

import { Formik } from "formik";
import { IconField, ColorField } from "../../constants";
import { createAction } from "../../api";

interface CreateFormProps {
  formRef: RefObject<HTMLFormElement> | null;
  afterSubmit?: () => void;
}

export const CreateForm: React.FC<CreateFormProps> = ({
  formRef,
  afterSubmit,
}) => {
  const [activeColor, setActiveColor] = useState("red");
  const [activeIcon, setActiveIcon] = useState("add");
  return (
    <Formik
      innerRef={formRef as any}
      initialValues={{ name: "New Action", description: "Description" }}
      validate={(values) => {
        const errors = {};
        if ((values as any).name === "") {
          (errors as any).name = "Required";
        }
        if ((values as any).description === "") {
          (errors as any).description = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        createAction(values.name, "", values.description, {
          color: activeColor,
          icon: activeIcon,
        }).then((data) => {
          setSubmitting(false);
          !!afterSubmit && afterSubmit();
        });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Text>Name</Text>
          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).name}
          />

          <IconField
            icons={[
              "calendar",
              "time",
              "star",
              "bell",
              "phone",
              "smile",
              "meh",
              "frown",
            ]}
            activeIcon={activeIcon}
            setActiveIcon={setActiveIcon}
          />
          <ColorField
            colors={[
              "red",
              "green",
              "blue",
              "yellow",
              "orange",
              "purple",
              "teal",
            ]}
            activeColor={activeColor}
            setActiveColor={setActiveColor}
          />

          <Text>Description</Text>
          <Input
            type="text"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).description}
          />
        </form>
      )}
    </Formik>
  );
};

export default CreateForm;
