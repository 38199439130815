import React, { useState } from "react";

import { Input } from "@chakra-ui/core";
interface CustomEditableProps {
  value: string;
  setValue: (value: string) => void;
  editing: boolean;
  exitEditing: () => void;
}

export const CustomEditable: React.FC<CustomEditableProps> = ({
  value,
  setValue,
  editing,
  exitEditing,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const handleEnter = () => {
    exitEditing();
    setValue(currentValue);
  };
  return (
    <>
      {!!editing ? (
        <Input
          type="text"
          value={currentValue}
          w="200px"
          textAlign="center"
          autoFocus
          onBlur={handleEnter}
          variant="flushed"
          onKeyUp={(e: React.KeyboardEvent<any>) => {
            if (e.key === "Enter") {
              handleEnter();
            }
          }}
          onChange={(v: React.ChangeEvent<any>) =>
            setCurrentValue(v.target.value)
          }
        />
      ) : (
        <>{!!currentValue && currentValue.toString()}</>
      )}
    </>
  );
};
