import React from "react";
import { Tab, PseudoBoxProps, Box } from "@chakra-ui/core";
import { FieldI, ColumnI, IRecord } from "../../types";

import Grid from "../../components/table/Grid";
type TabProps = PseudoBoxProps & React.ButtonHTMLAttributes<any>;

interface GridTabPanelProps {
  columns: ColumnI[];
  data: any;
  records: IRecord[];
  fields: FieldI[];
}

const GridTab: React.ForwardRefRenderFunction<React.PropsWithChildren<
  TabProps
>> = (props, ref) => {
  return (
    <Tab
      borderWidth="1px"
      rounded="md"
      m={3}
      _selected={{
        borderColor: "gray.500",
        bg: "gray.100",
      }}
      ref={ref}
      {...props}
    >
      Table
    </Tab>
  );
};
const GridTabPanel: React.FC<GridTabPanelProps> = ({
  columns,
  data,
  records,
  fields,
}) => {
  return (
    <Box w="full" overflow="scroll">
      <Grid columns={columns} data={data} records={records} fields={fields} />
    </Box>
  );
};

export default {
  Tab: React.forwardRef(GridTab),
  Panel: GridTabPanel,
};
