import React, { useRef } from "react";
import CreateForm from "./CreateForm";
import { OddModal } from "oddular-common";
import { Button } from "@chakra-ui/core";

interface CreateModalProps {
  baseID: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateModal: React.FC<CreateModalProps> = ({
  baseID,
  isOpen,
  onClose,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleFormSubmit = () => {
    if (formRef !== null) {
      formRef.current!.handleSubmit();
    }
  };

  return (
    <OddModal
      context="default"
      header="Create Table"
      children={
        <CreateForm
          formRef={formRef}
          baseID={baseID}
          afterSubmit={() => {
            onClose();
          }}
        />
      }
      footer={[
        <Button size="sm" variantColor="gray" onClick={onClose}>
          Cancel
        </Button>,
        <Button size="sm" onClick={handleFormSubmit} variantColor="blue">
          Create
        </Button>,
      ]}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default CreateModal;
