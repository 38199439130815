import React, { useState } from "react";
import { useRecoilState } from "recoil";

import { baseListState } from "./atom";
import {
  Box,
  Text,
  Icon,
  SimpleGrid,
  Button,
  useDisclosure,
} from "@chakra-ui/core";

import { BaseI } from "../../types";
import CreateBaseModal from "../../components/modals/CreateBaseModal";
import BaseCard from "../../components/lib/BaseCard";

interface BaseListProps {}

export const BaseList: React.FC<BaseListProps> = () => {
  const [baseList] = useRecoilState(baseListState);
  const [hover, setHover] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            as="h1"
            textTransform="uppercase"
            color="black"
            fontWeight="1000"
            fontSize="6xl"
          >
            Bases
          </Text>
          <Button
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            _hover={{ bg: "white" }}
            _active={{ bg: "white" }}
            bg="white"
            variant="solid"
            w="60px"
            h="60px"
            onClick={onOpen}
          >
            <Icon name="add" size={hover ? "50px" : "25px"} color="green.500" />
            <CreateBaseModal isOpen={isOpen} onClose={onClose} />
          </Button>
        </Box>
        <Box bg="white" borderRadius="xl" p={10} boxShadow="md">
          <SimpleGrid columns={{ base: 2, lg: 4 }} spacing={10}>
            {baseList.results.map((base: BaseI) => {
              return <BaseCard key={base.id} base={base} />;
            })}
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default BaseList;
