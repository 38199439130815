import React, { useEffect } from "react";

import { Box, Tabs, TabPanels, TabPanel, TabList } from "@chakra-ui/core";

import { ColumnI, FieldI, IRecord } from "../../types";
import CardView from "../../features/fluxTable/CardView";
import GridView from "../../features/fluxTable/GridView";
import JsonView from "../../features/fluxTable/JsonView";
import FieldView from "../../features/fluxTable/FieldView";
import FormView from "../../features/fluxTable/FormView";

interface TableProps {
  tableID: string;
  baseID: string;
  columns: ColumnI[];
  fields: FieldI[];
  onRefreshTable?: () => void;
  records: IRecord[];
  data: never[];
  refreshRecords: () => void;
}

export const Table: React.FC<TableProps> = ({
  tableID,
  baseID,
  columns,
  fields,
  onRefreshTable,
  records,
  data,
  refreshRecords,
}) => {
  useEffect(
    () => {
      refreshRecords();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box>
      <Tabs variant="unstyled" defaultIndex={1}>
        <TabList>
          <CardView.Tab />
          <GridView.Tab />
          <JsonView.Tab />
          <FieldView.Tab />
          <FormView.Tab />
        </TabList>
        <TabPanels p={2}>
          <TabPanel>
            <CardView.Panel
              columns={columns}
              data={data}
              fields={fields}
              records={records}
            />
          </TabPanel>
          <TabPanel>
            <GridView.Panel
              columns={columns}
              data={data}
              records={records}
              fields={fields}
            />
          </TabPanel>
          <TabPanel>
            <JsonView.Panel columns={columns} data={data} fields={fields} />:
          </TabPanel>
          <TabPanel>
            <FieldView.Panel
              tableID={tableID}
              baseID={baseID}
              fields={fields}
              onRefreshTable={onRefreshTable}
            />
          </TabPanel>
          <TabPanel>
            <FormView.Panel fields={fields} tableID={tableID} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Table;
