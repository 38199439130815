import React, { useRef } from "react";

import DeleteForm from "./DeleteForm";
import { OddModal } from "oddular-common";
import { Button } from "@chakra-ui/core";

interface DeleteModalProps {
  baseID: string;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  baseID,
  isOpen,
  onClose,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleFormSubmit = () => {
    if (formRef !== null) {
      formRef.current!.handleSubmit();
    }
  };
  return (
    <OddModal
      context="default"
      header="Delete Base"
      children={
        <DeleteForm baseID={baseID} formRef={formRef} afterSubmit={onClose} />
      }
      footer={[
        <Button size="sm" variantColor="gray" onClick={onClose}>
          Cancel
        </Button>,
        <Button size="sm" onClick={handleFormSubmit} variantColor="blue">
          Delete
        </Button>,
      ]}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default DeleteModal;
