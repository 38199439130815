import React, { useState } from "react";

import { Text, Input, Icon } from "@chakra-ui/core";
interface EditableProps {
  value: string;
  setValue: (value: string) => void;
}

export const Editable: React.FC<EditableProps> = ({ value, setValue }) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [lastClick, setLastClick] = useState(0);
  const handleClick = () => {
    const currentTime = Date.now();
    if (lastClick + 700 > currentTime) {
      setEditing(true);
    } else {
      setLastClick(Date.now());
    }
  };
  const handleEnter = () => {
    setEditing(false);
    setValue(currentValue);
  };
  return (
    <>
      {!!editing ? (
        <Input
          type="text"
          value={currentValue}
          w="200px"
          textAlign="center"
          autoFocus
          onBlur={handleEnter}
          variant="flushed"
          onKeyUp={(e: React.KeyboardEvent<any>) => {
            if (e.key === "Enter") {
              handleEnter();
            }
          }}
          onChange={(v: React.ChangeEvent<any>) =>
            setCurrentValue(v.target.value)
          }
        />
      ) : currentValue === "" ? (
        <Icon name="edit" onClick={handleClick} />
      ) : (
        <Text onClick={handleClick}>{currentValue}</Text>
      )}
    </>
  );
};
