import { API as oddAPI } from "oddular-common";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.oddular.com"
    : "http://localhost:8000"; //localhost

export enum LoadingStatus {
  Default,
  Loading,
  Loaded,
  Updating,
  Updated,
}

export const API = oddAPI;

export const requestTokens = (username: string, password: string) => {
  return axios({
    url: `${BASE_URL}/api/token/`,
    data: { username, password },
    method: "POST",
  }).then((response) => response.data);
};

export const requestAccessToken = (refresh: string) => {
  return axios({
    url: `${BASE_URL}/api/token/refresh/`,
    data: { refresh },
    method: "POST",
  }).then((response) => response.data);
};

export const tokenExpirationInMilliseconds = (token: string) => {
  try {
    const decoded = jwt_decode(token);
    // Calculate percentage in milliseconds
    return decoded;
  } catch (error) {
    return -1;
  }
};

export const getProfile = () => {
  return API()
    .get("/users/profile/")
    .then((result) => result.data);
};
export const getTableList = (baseID: string) => {
  return API()
    .get("/flux/table/")
    .then((result) => result.data);
};

export const getTables = () => {
  return API()
    .get("/flux/table/")
    .then((result) => result.data);
};

export const getRecords = (tableID: string) => {
  return API()
    .get(`/flux/table/${tableID}/records/`)
    .then((result) => result.data);
};

export const getTable = (tableID: string) => {
  return API()
    .get(`/flux/table/${tableID}/`)
    .then((result) => result.data);
};

export const createField = (
  table: string,
  name: string,
  slug: string,
  field_type: string,
  linked_record_table?: string
) => {
  if (linked_record_table) {
    return API()
      .post(`/flux/field/`, {
        table,
        name,
        slug,
        field_type,
        meta: { linked_record_table },
      })
      .then((result) => result.data);
  }
  return API()
    .post(`/flux/field/`, { table, name, slug, field_type })
    .then((result) => result.data);
};

export const getBase = (baseID: string) => {
  return API()
    .get(`/flux/base/${baseID}/`)
    .then((result) => result.data);
};

export const getBaseList = () => {
  return API()
    .get("/flux/base/")
    .then((result) => result.data);
};

export const getTablesList = (baseID: string) => {
  return API()
    .get(`/flux/base/${baseID}/tables/`)
    .then((result) => result.data);
};

export const createBase = (name: string, slug: string, meta: any) => {
  return API()
    .post(`/flux/base/`, { name, slug, meta })
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const deleteBase = (baseID: string) => {
  return API()
    .delete(`/flux/base/${baseID}/`)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const renameBase = (baseID: string, name: string) => {
  return API()
    .patch(`/flux/base/${baseID}/`, { name })
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const editBase = (baseID: string, params: any) => {
  return API()
    .patch(`/flux/base/${baseID}/`, params)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const createTable = (
  baseID: string,
  name: string,
  slug: string,
  description: string,
  meta: any
) => {
  return API()
    .post("/flux/table/", {
      base: baseID,
      name,
      slug,
      description,
      meta,
    })
    .then((result) => {
      console.log(JSON.stringify(result));
    });
};

export const renameTable = (tableID: string, name: string) => {
  return API()
    .patch(`/flux/table/${tableID}/`, { name })
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const editTable = (tableID: string, params: any) => {
  return API()
    .patch(`/flux/table/${tableID}/`, params)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const deleteTable = (tableID: string) => {
  return API()
    .delete(`/flux/table/${tableID}/`)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const renameField = (fieldID: string, name: string) => {
  return API()
    .patch(`/flux/field/${fieldID}/`, { name })
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const editField = (fieldID: string, params: any) => {
  return API()
    .patch(`/flux/field/${fieldID}/`, params)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const deleteField = (fieldID: string) => {
  return API()
    .delete(`/flux/field/${fieldID}/`)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const getField = (fieldID: string) => {
  return API()
    .get(`/flux/field/${fieldID}/`)
    .then((result) => result.data);
};

export const createData = (tableID: string, values: any) => {
  return API()
    .post(`/flux/p/table/${tableID}/submit/`, values)
    .then((result) => result.data);
};

export const getAction = (actionID: string) => {
  return API()
    .get(`/flux/action/${actionID}/`)
    .then((result) => result.data);
};

export const getActionList = () => {
  return API()
    .get("/flux/action/")
    .then((result) => result.data);
};

export const createAction = (
  name: string,
  slug: string,
  description: string,
  meta: any
) => {
  return API()
    .post(`/flux/action/`, { name, slug, description, meta })
    .then((result) => result.data);
};

export const deleteAction = (actionID: string) => {
  return API()
    .delete(`/flux/action/${actionID}/`)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export const editAction = (actionID: string, params: any) => {
  return API()
    .patch(`/flux/action/${actionID}/`, params)
    .then((result) => console.log(JSON.stringify(result.data)));
};

export default {
  requestTokens,
  requestAccessToken,
  tokenExpirationInMilliseconds,
};
