import React, { useEffect, useState } from "react";

import { Text, Box, Input, Select } from "@chakra-ui/core";

import { FieldI, IRecord, TableI } from "../../types";
import { flux } from "oddular-common";

interface AdaptiveRecordInputProps {
  field: FieldI;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  value: any;
}

export const AdaptiveRecordInput: React.FC<AdaptiveRecordInputProps> = ({
  field,
  handleChange,
  handleBlur,
  value,
}) => {
  const [records, setRecords] = useState([] as IRecord[]);
  const [table, setTable] = useState({} as TableI);

  useEffect(() => {
    if (field.field_type === "linked_record") {
      flux.table.records(field.meta.linked_record_table).then((records) => {
        setRecords(records);
      });
      flux.table.detail(field.meta.linked_record_table).then((table) => {
        setTable(table);
      });
    }
  }, [field, setRecords]);
  switch (field.field_type) {
    case "url":
      return (
        <Box key={field.slug}>
          <Text>{field.name}</Text>
          <Input
            type="text"
            name={field.slug}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
          />
        </Box>
      );
    case "single_select":
      return (
        <Box key={field.slug}>
          <Text>{field.name}</Text>
          {/* <Text>{JSON.stringify(field)}</Text> */}
          <Select
            name={field.slug}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
          >
            {field.meta.choices.map(
              (choice: { slug: string; label: string }) => {
                return <option value={choice.slug}>{choice.label}</option>;
              }
            )}
          </Select>
        </Box>
      );
    case "linked_record":
      return (
        <Box key={field.slug}>
          <Text>{field.name}</Text>
          <Select
            placeholder="Select a Record"
            name={field.slug}
            onChange={(v) => {
              handleChange(v);
            }}
            onBlur={handleBlur}
          >
            {!!records &&
              !!table &&
              !!table.meta &&
              records.map((record: IRecord) => {
                return (
                  <option value={record.id}>
                    {record.values[table.meta.linked_record_field]}
                  </option>
                );
              })}
          </Select>
        </Box>
      );
    case "number":
    case "multi_line_text":
    case "markdown":
    case "datetime":
    case "phone_number":
    case "email":
    case "single_line_text":
    case "image":
    case "video":
    case "document":
    case "file":
    default:
      return (
        <Box key={field.slug}>
          <Text>{field.name}</Text>
          <Input
            type="text"
            name={field.slug}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
          />
        </Box>
      );
  }
};

export default AdaptiveRecordInput;
