import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Box, Spinner } from "@chakra-ui/core";

import { TableI } from "../features/fluxTable/types";

import DisplayTable from "../components/table/DisplayTable";
import { getTable } from "../api";

interface FluxTablePageProps {}

const FluxTablePage: React.FC<FluxTablePageProps> = () => {
  let { id } = useParams();
  const [table, setTable] = useState<TableI | undefined>(undefined);

  const refreshTable = (id: string) => {
    getTable(id).then((data) => {
      setTable(data);
    });
  };
  useEffect(() => {
    if (!!id) {
      refreshTable(id);
    }
  }, [id]);
  if (!!!table) {
    return (
      <Box display="flex" w="full" alignItems="center" justifyContent="center">
        <Spinner color="purple.500" size="xl" />
      </Box>
    );
  }
  return (
    <Box>
      <DisplayTable
        name={table.name}
        base={table.base}
        fields={table.fields}
        id={table.id}
        color={table.meta.color}
        onRefreshTable={() => {
          refreshTable(id);
        }}
      />
    </Box>
  );
};

export default FluxTablePage;
