import React from "react";
import { Box, IconButton } from "@chakra-ui/core";

interface ColorSelectorProps {
  color: string;
  activeColor: string;
  setActiveColor: (color: string) => void;
}

export const ColorSelector: React.FC<ColorSelectorProps> = ({
  color,
  activeColor,
  setActiveColor,
}) => {
  return (
    <Box
      rounded="lg"
      w="40px"
      h="40px"
      borderWidth={activeColor === color ? "2px" : "0px"}
      borderColor="black"
      bg={`${color}.500`}
      onClick={() => setActiveColor(color)}
      m={2}
    />
  );
};

interface ColorFieldProps {
  colors: string[];
  activeColor: string;
  setActiveColor: (color: string) => void;
}

export const ColorField: React.FC<ColorFieldProps> = ({
  colors,
  activeColor,
  setActiveColor,
}) => {
  return (
    <Box
      p={3}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {colors.map((c) => {
        return (
          <ColorSelector
            color={c}
            activeColor={activeColor}
            setActiveColor={setActiveColor}
          />
        );
      })}
    </Box>
  );
};

interface IconSelectorProps {
  icon: string;
  activeIcon: string;
  setActiveIcon: (icon: string) => void;
}

export const IconSelector: React.FC<IconSelectorProps> = ({
  icon,
  activeIcon,
  setActiveIcon,
}) => {
  return (
    <IconButton
      icon={icon as any}
      aria-label={`${icon} icon selector`}
      bg="white"
      rounded="lg"
      borderWidth={activeIcon === icon ? "2px" : "0px"}
      borderColor="black"
      variant="ghost"
      onClick={() => setActiveIcon(icon)}
      m={2}
    />
  );
};

export default IconSelector;

interface IconFieldProps {
  icons: string[];
  activeIcon: string;
  setActiveIcon: (icon: string) => void;
}

export const IconField: React.FC<IconFieldProps> = ({
  icons,
  activeIcon,
  setActiveIcon,
}) => {
  return (
    <Box
      pt={3}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {icons.map((i) => {
        return (
          <IconSelector
            icon={i}
            activeIcon={activeIcon}
            setActiveIcon={setActiveIcon}
          />
        );
      })}
    </Box>
  );
};
