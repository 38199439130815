import React, { useState } from "react";

import { useTheme, Text, Box, PseudoBox, Icon } from "@chakra-ui/core";

import AdaptiveField from "./AdaptiveField";
import { FieldI } from "../../types";

interface CardProps {
  data: any;
  fields: FieldI[];
  recordID: string;
}

export const Card: React.FC<CardProps> = ({ data, fields, recordID }) => {
  const [, setImage] = useState("");
  const [, setVideo] = useState("");
  const theme = useTheme();
  return (
    <PseudoBox
      rounded="md"
      py={2}
      px={4}
      borderWidth="1px"
      bg="white"
      _hover={{
        borderColor: theme.colors.gray["500"],
        bg: theme.colors.gray["100"],
        boxShadow: `5px 10px ${theme.colors.purple["500"]}`,
      }}
      m={"1rem"}
      display="flex"
      flexDirection="column"
      key={data.id}
    >
      {Object.entries(data).map((pair: [string, any]) => {
        const field = fields.find((field: FieldI) => {
          return field.slug === pair[0];
        });
        return !!field ? (
          <Box key={field.id} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Icon name={`${field.field_type}_field`} mr={2} />
              <Text
                as="h2"
                fontWeight={1000}
                color={theme.colors.purple["500"]}
              >
                {pair[0] + ": "}
              </Text>
            </Box>
            <AdaptiveField
              value={pair[1]}
              field={field}
              recordID={recordID}
              setVideo={(video) => {
                setVideo(video);
              }}
              setImage={(image) => {
                setImage(image);
              }}
            ></AdaptiveField>
          </Box>
        ) : (
          <Box key={pair[0]} />
        );
      })}
    </PseudoBox>
  );
};

export default Card;
