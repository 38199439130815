import { MenuButton, IconButton } from "@chakra-ui/core";
import React from "react";

export const EllipsisMenuButton = () => {
  return (
    <MenuButton
      as={IconButton}
      {...{ icon: "ellipsis" }}
      borderRadius="10rem"
    />
  );
};
