import React, { ReactElement } from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from "@chakra-ui/core";

interface SideDrawerProps {
  open: boolean;
  onClose: () => void;
  finalFocusRef: React.RefObject<HTMLElement> | undefined;
  content: ReactElement | string;
  header: ReactElement | string;
  footer: ReactElement | string;
}
export const SideDrawer: React.FC<SideDrawerProps> = ({
  open,
  onClose,
  finalFocusRef,
  content,
  header,
  footer,
}) => {
  return (
    <Drawer
      isOpen={open}
      placement="right"
      onClose={onClose}
      finalFocusRef={finalFocusRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{header}</DrawerHeader>
        <DrawerBody>{content}</DrawerBody>
        <DrawerFooter>
          {footer}
          <Button onClick={onClose}>Cancel</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
