import React from "react";
import "./App.css";

import { Box } from "@chakra-ui/core";
import BaseList from "./features/fluxBase/List";

function App() {
  return (
    <Box>
      <BaseList />
    </Box>
  );
}

export default App;
