import React from "react";
import { Flex, Stack, MenuItem } from "@chakra-ui/core";

import UserMenu from "../user/menu/Menu";
import { OddNavMenu } from "../../components/lib/OddNavMenu";
import { Link } from "react-router-dom";

interface TopbarProps {}

const Topbar: React.FC<TopbarProps> = () => {
  return (
    <Flex
      bg="white"
      justifyContent="space-between"
      px={4}
      py={1}
      alignItems="center"
    >
      <OddNavMenu
        menuItems={[
          <Link to="/action/">
            <MenuItem key={0}>Actions</MenuItem>
          </Link>,
          <Link to="/">
            <MenuItem key={1}>Bases</MenuItem>
          </Link>,
        ]}
        title="Flux"
      />
      <Stack spacing={5} isInline display="flex" alignItems="center">
        <UserMenu />
      </Stack>
    </Flex>
  );
};

export default Topbar;
