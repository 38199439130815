import React, { useEffect, useState } from "react";

import { useParams, Link } from "react-router-dom";

import {
  Icon,
  Box,
  Text,
  Menu,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/core";

import { IAction } from "../types";

import { EditModal } from "../features/fluxAction/EditModal";
import { DeleteModal } from "../features/fluxAction/DeleteModal";
import { EllipsisMenuButton } from "../components/menu/EllipsisMenuButton";
import { getAction } from "../api";

interface FluxActionPageProps {}

const FluxActionPage: React.FC<FluxActionPageProps> = () => {
  let { id } = useParams();
  const [action, setAction] = useState({} as IAction);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);

  const refreshPage = (id: string) => {
    if (!!id) {
      getAction(id).then((data) => {
        setAction(data);
      });
    }
  };
  useEffect(() => {
    refreshPage(id);
  }, [id]);
  if (Object.keys(action).length === 0) {
    return null;
  }
  return (
    <Box borderWidth="2px" borderColor={`${action.meta.color}.500`} p={3} m={3}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <EditModal
          actionID={id}
          isOpen={edit}
          onClose={() => {
            setEdit(false);
            refreshPage(id);
          }}
        />
        <DeleteModal
          actionID={id}
          isOpen={del}
          onClose={() => {
            setDel(false);
            refreshPage(id);
            window.location.replace("/");
          }}
        />
        <Link to="/action">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="arrow-back" size="25px" m={3} />
            <Text fontSize="lg">Actions</Text>
          </Box>
        </Link>
        <Menu>
          <EllipsisMenuButton />
          <MenuList>
            <MenuItem>
              <Button onClick={() => setEdit(true)} variant="ghost">
                <Icon name="edit" m={2} color="blue.500" />
                <Text color="blue.500">Edit Action</Text>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button onClick={() => setDel(true)} variant="ghost">
                <Icon name="delete" m={2} color="red.500" />
                <Text color="red.500">Delete Action</Text>
              </Button>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        px={3}
      >
        <Text fontSize="3xl" fontWeight="1000">
          {(action as any).name}
        </Text>
      </Box>
    </Box>
  );
};

export default FluxActionPage;
