import React, { useState } from "react";
import { flux } from "oddular-common";
import { Text, Box, Collapse, Icon, PseudoBox } from "@chakra-ui/core";

import EditForm from "../../features/fluxField/EditForm";
import DeleteForm from "../../features/fluxField/DeleteForm"
import { FieldI } from "../../types";

interface AdaptiveInputProps {
  field: FieldI;
  onRefreshTable?: () => void;
}

export const AdaptiveInput: React.FC<AdaptiveInputProps> = ({
  field,
  onRefreshTable,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Box m={2}>
      <PseudoBox
        onClick={() => setOpen(!open)}
        w="full"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={open ? "gray.100" : "white"}
        _hover={{ bg: "gray.100" }}
        _active={{ bg: "gray.100" }}
        roundedTop="lg"
        roundedBottom={open ? "none" : "lg"}
        px={8}
        py={2}
      >
        <Box display="block" textAlign="left">
          <Text fontWeight="1000" color="purple.500">
            {field.name}
          </Text>
          <Text mt={1} fontWeight="300" fontSize="xs">
            {flux.field.prettyFieldType(field.field_type as any)}
          </Text>
        </Box>
        <Icon
          name="add"
          transform={open ? "rotate(45deg)" : ""}
          transition="0.2s ease-in-out"
        />
      </PseudoBox>
      <Collapse isOpen={open}>
        <Box bg="gray.100" px={10} py={8} roundedBottom="lg">
          <EditForm fieldID={field.id} />
          <DeleteForm fieldID={field.id} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default AdaptiveInput;
