import React, { useState, useEffect, RefObject } from "react";

import { Text, Input } from "@chakra-ui/core";

import { Formik } from "formik";
import { getBase, deleteBase } from "../../api";

interface DeleteFormProps {
  baseID: string;
  formRef: RefObject<HTMLFormElement> | null;
  afterSubmit?: () => void;
}

export const DeleteForm: React.FC<DeleteFormProps> = ({
  baseID,
  formRef,
  afterSubmit,
}) => {
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    if (!!baseID) {
      getBase(baseID).then((result) => {
        setInitialValues({ name: result.name, slug: result.slug });
      });
    }
  }, [baseID]);
  return (
    <Formik
      innerRef={formRef as any}
      initialValues={{ name: "" }}
      validate={(values) => {
        const errors = {};
        if ((values as any).name !== (initialValues as any).name) {
          (errors as any).name = "Needs to match base name";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        deleteBase(baseID).then((data) => {
          setSubmitting(false);
          !!afterSubmit && afterSubmit();
        });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Text>Write the text below to confirm deletion</Text>
          <Text
            userSelect="none"
            as="kbd"
            borderWidth="2px"
            borderColor="red.500"
            fontWeight="800"
            p={1}
            ml={4}
            fontFamily="mono"
          >
            {(initialValues as any).name}
          </Text>
          <Input
            m={1}
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </form>
      )}
    </Formik>
  );
};

export default DeleteForm;
