import React from "react";

import { Box } from "@chakra-ui/core";
import ActionList from "../features/fluxAction/List";

function App() {
  return (
    <Box>
      <ActionList />
    </Box>
  );
}

export default App;
