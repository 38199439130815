import { Box, Text, Button, Icon } from "@chakra-ui/core";
import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { FieldI } from "../../types";
import seedrandom from "seedrandom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Editable } from "../lib/Editable";
import { FormRender } from "./FormRender";

interface FormProps {
  title: string;
  description: string;
  fields: FieldI[];
  backgroundColor: string;
}

export const Form: React.FC<FormProps> = ({
  title,
  description,
  fields,
  backgroundColor,
}) => {
  const [currentOrder, setCurrentOrder] = useState({
    fields,
    hidden: fields.map(() => {
      return false;
    }),
  });
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentDescription, setCurrentDescription] = useState(description);

  const reorder = (start: number, end: number) => {
    const result = currentOrder.fields;
    const resultHidden = currentOrder.hidden;
    const [removed] = result.splice(start, 1);
    const [removedHidden] = resultHidden.splice(start, 1);
    result.splice(end, 0, removed);
    resultHidden.splice(end, 0, removedHidden);
    setCurrentOrder({ fields: result, hidden: resultHidden });
  };
  const swap = (startIndex: number, endIndex: number) => {
    let newOrder = currentOrder.fields;
    let newOrderHidden = currentOrder.hidden;
    let tmp = newOrder[startIndex];
    let tmpHidden = newOrderHidden[startIndex];
    newOrder[startIndex] = newOrder[endIndex];
    newOrder[endIndex] = tmp;
    newOrderHidden[startIndex] = newOrderHidden[endIndex];
    newOrderHidden[endIndex] = tmpHidden;
    setCurrentOrder({ fields: newOrder, hidden: newOrderHidden });
  };
  const moveUp = (index: number) => {
    swap(index, index - 1);
  };
  const moveDown = (index: number) => {
    swap(index, index + 1);
  };
  const hide = (index: number) => {
    /* let newOrder = currentOrder.fields; */
    /* newOrder.splice(index, 1); */
    /* setCurrentOrder({ fields: newOrder, hidden: currentOrder.hidden }); */
    let newOrderHidden = currentOrder.hidden;
    newOrderHidden[index] = !newOrderHidden[index];
    setCurrentOrder({
      fields: currentOrder.fields,
      hidden: newOrderHidden,
    });
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRight="3px solid"
          px={10}
          w="500px"
          bg={backgroundColor}
        >
          <Box
            fontSize="3xl"
            fontWeight="1000"
            borderWidth="1px"
            px={2}
            my={2}
            textAlign="center"
          >
            <Editable value={currentTitle} setValue={setCurrentTitle} />
          </Box>
          <Box
            fontSize="2xl"
            fontWeight="500"
            borderWidth="2px"
            px={2}
            textAlign="center"
          >
            <Editable
              value={currentDescription}
              setValue={setCurrentDescription}
            />
          </Box>
          <DragDropContext
            onDragEnd={(result) => {
              if (!!result && !!result.destination) {
                reorder(result.source.index, result.destination.index);
              }
            }}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {currentOrder.fields.map((field, index) => {
                    return (
                      <Draggable
                        key={field.id}
                        draggableId={field.id}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            bg={`hsl(${
                              seedrandom(field.id)() * 360
                            }, 100%, 50%)`}
                            opacity={!!currentOrder.hidden[index] ? 0.3 : 1}
                            p={2}
                            m={1}
                            rounded="lg"
                          >
                            <Icon name="drag-handle" mr={2} color="white" />
                            <Text mr={2} bg="white" rounded="lg" p={1}>
                              {field.name}
                            </Text>
                            <Box
                              borderColor="black"
                              borderWidth="1px"
                              rounded="lg"
                            >
                              {index > 0 && (
                                <Icon
                                  name="chevron-up"
                                  onClick={() => {
                                    moveUp(index);
                                  }}
                                  color="white"
                                  p={1}
                                  m={1}
                                  size="30px"
                                />
                              )}
                              {index < fields.length - 1 && (
                                <Icon
                                  name="chevron-down"
                                  onClick={() => {
                                    moveDown(index);
                                  }}
                                  color="white"
                                  p={1}
                                  m={1}
                                  size="30px"
                                />
                              )}
                              <Icon
                                name={
                                  !!currentOrder.hidden[index]
                                    ? "view"
                                    : "view-off"
                                }
                                onClick={() => {
                                  hide(index);
                                }}
                                color="white"
                                p={1}
                                m={1}
                                size="30px"
                              />
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            type="submit"
            onClick={() => {
              alert(
                fields
                  .filter((field, index) => !currentOrder.hidden[index])
                  .map((field) => field.name)
              );
            }}
          >
            Save
          </Button>
        </Box>
        <FormRender
          fields={fields}
          backgroundColor={backgroundColor}
          currentTitle={currentTitle}
          currentDescription={currentDescription}
          currentOrder={currentOrder}
        />
      </Box>
      <Box w="900px" ml="100px">
        <Text fontSize="3xl">Output:</Text>
        <Text>
          {ReactDOMServer.renderToStaticMarkup(
            <form>
              {fields
                .filter((field, index) => !currentOrder.hidden[index])
                .map((field) => {
                  return (
                    <div>
                      <label htmlFor={field.slug}>{field.name}</label>
                      <input type="text" id={field.slug} />
                    </div>
                  );
                })}
              <button type="submit">Submit</button>
            </form>
          )}
        </Text>
      </Box>
    </Box>
  );
};
