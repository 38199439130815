import React from "react";
import { Link, PseudoBox, Text, useTheme, Icon } from "@chakra-ui/core";
import { TableI } from "../../types";

interface TableCardProps {
  table: TableI;
}

export const TableCard: React.FC<TableCardProps> = ({ table }) => {
  const theme = useTheme();
  return (
    <Link href={`/table/${table.id}`}>
      <PseudoBox
        padding={6}
        boxShadow="sm"
        borderRadius="1rem"
        transition="all .15s ease-in-out"
        borderWidth={3}
        bg={`${table.meta.color}.400`}
        color="white"
        borderColor={`${table.meta.color}.300`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        _hover={{
          boxShadow: theme.shadows.md,
          transform: "translate(0, -1px)",
        }}
      >
        <Icon name={table.meta.icon} fontSize="4xl" my={2} />
        <Text fontSize="lg" fontWeight="700" my={2}>
          {table.name}
        </Text>
        <Text fontSize="sm" fontWeight="400" my={2}>
          {table.description}
        </Text>
      </PseudoBox>
    </Link>
  );
};

export default TableCard;
