import React, { useState, RefObject } from "react";

import { Text, Input } from "@chakra-ui/core";

import { Formik } from "formik";
import { IconField, ColorField } from "../../constants";
import { createTable } from "../../api";

interface CreateFormProps {
  baseID: string;
  afterSubmit?: () => void;
  formRef: RefObject<HTMLFormElement> | null;
}

export const CreateForm: React.FC<CreateFormProps> = ({
  baseID,
  afterSubmit,
  formRef,
}) => {
  const [color, setColor] = useState("red");
  const [icon, setIcon] = useState("textfile");
  return (
    <Formik
      innerRef={formRef as any}
      initialValues={{ name: "New Table", description: "" }}
      validate={(values) => {
        const errors = {};
        if ((values as any).name === "") {
          (errors as any).name = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        createTable(baseID, values.name, "", values.description, {
          color,
          icon,
        }).then((data) => {
          setSubmitting(false);
          !!afterSubmit && afterSubmit();
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Text>Name</Text>
          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).name}
          />
          <IconField
            icons={[
              "calendar",
              "time",
              "star",
              "bell",
              "phone",
              "smile",
              "meh",
              "frown",
            ]}
            activeIcon={icon}
            setActiveIcon={setIcon}
          />
          <ColorField
            colors={[
              "red",
              "green",
              "blue",
              "yellow",
              "orange",
              "purple",
              "teal",
            ]}
            activeColor={color}
            setActiveColor={setColor}
          />
          <Text>Description</Text>
          <Input
            type="text"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).description}
          />
        </form>
      )}
    </Formik>
  );
};

export default CreateForm;
