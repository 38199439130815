import React from "react";

import { SimpleGrid } from "@chakra-ui/core";

import Card from "./Card";
import { FieldI, IRecord } from "../../types";

interface GridProps {
  data: any;
  fields: FieldI[];
  records: IRecord[];
}

export const Grid: React.FC<GridProps> = ({ data, fields, records }) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
      {data.map((d: any, index: number) => {
        return (
          <Card
            key={d.id}
            data={d}
            recordID={records[index].id}
            fields={fields}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default Grid;
