import React from "react";

import DeleteForm from "./DeleteForm";
import { OddModal } from "oddular-common";

interface DeleteModalProps {
  actionID: string;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  actionID,
  isOpen,
  onClose,
}) => {
  return (
    <OddModal
      context="default"
      header="Delete Action"
      children={<DeleteForm actionID={actionID} afterSubmit={onClose} />}
      footer=""
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default DeleteModal;
