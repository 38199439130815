import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  RouteProps,
  Switch,
} from "react-router-dom";

import ProductPage from "./pages/Product";
import AboutPage from "./pages/About";
import LoginPage from "./pages/Login";
import FluxBasePage from "./pages/FluxBase";
import Actions from "./pages/Actions";
import FluxTablePage from "./pages/FluxTable";
import App from "./App";
import { Flex, Box } from "@chakra-ui/core";
import Page from "./features/page/Page";
import Topbar from "./features/topbar/Topbar";
import FluxActionPage from "./pages/FluxAction";

interface PrivateRouteProps extends RouteProps {
  dashboard: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ dashboard, ...rest }) => {
  const isAuthenticated = true;

  if (isAuthenticated) {
    if (dashboard) {
      return (
        <Box w="full" mx="auto">
          <Topbar />
          <Flex flex="1 1 0" bg="white">
            <Page sidebar>
              <Route {...rest} />
            </Page>
          </Flex>
        </Box>
      );
    } else {
      return <Route {...rest} />;
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

const FullPageRoute: React.FC<RouteProps> = ({ ...rest }) => {
  return (
    <>
      <Flex flex="1 1 0" bg="white" flexDirection="column">
        <Route {...rest} />
      </Flex>
      <Flex justifyContent="center" alignItems="center" bg="gray.100">
        &copy; Oddular
      </Flex>
    </>
  );
};

const NotFound = () => {
  return <h1>404 - Page not found</h1>;
};

const AppRouter = () => {
  return (
    <Flex flex="1 1 auto" flexDirection="column" minH="100vh">
      <Router>
        <Switch>
          <PrivateRoute path="/" exact dashboard component={App} />
          <FullPageRoute path="/login" exact component={LoginPage} />
          <PrivateRoute path="/action/" exact dashboard component={Actions} />
          <PrivateRoute
            path="/action/:id/"
            exact
            dashboard
            component={FluxActionPage}
          />
          <PrivateRoute
            path="/base/:id/"
            exact
            dashboard
            component={FluxBasePage}
          />
          <PrivateRoute
            path="/table/:id/"
            exact
            dashboard
            component={FluxTablePage}
          />
          <PrivateRoute
            path="/product"
            exact
            dashboard
            component={ProductPage}
          />
          <PrivateRoute path="/about" exact dashboard component={AboutPage} />
          <FullPageRoute component={NotFound} />
        </Switch>
      </Router>
    </Flex>
  );
};

export default AppRouter;
