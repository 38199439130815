import React, { useState, useEffect, RefObject } from "react";

import { Text, Input } from "@chakra-ui/core";

import { Formik } from "formik";
import { ColorField, IconField } from "../../constants";
import { getBase, editBase } from "../../api";

interface EditFormProps {
  baseID: string;
  formRef: RefObject<HTMLFormElement> | null;
  afterSubmit?: () => void;
}

export const EditForm: React.FC<EditFormProps> = ({
  baseID,
  formRef,
  afterSubmit,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [color, setColor] = useState("red");
  const [icon, setIcon] = useState("textfile");
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!!baseID) {
      getBase(baseID).then((result) => {
        setInitialValues({
          name: result.name,
          slug: result.slug,
          description: result.meta.description,
        });
        setReady(true);
      });
    }
  }, [baseID, setReady]);
  if (!ready) {
    return null;
  }
  return (
    <Formik
      innerRef={formRef as any}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if ((values as any).name === "") {
          (errors as any).name = "Required";
        }
        if ((values as any).slug === "") {
          (errors as any).slug = "Required";
        }
        return {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        editBase(baseID, {
          name: (values as any).name,
          slug: (values as any).slug,
          meta: { color, icon, description: (values as any).description },
        }).then((data) => {
          !!afterSubmit && afterSubmit();
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Text>Name</Text>
          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).name}
          />
          <Text>Slug</Text>
          <Input
            type="text"
            name="slug"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).slug}
          />
          <IconField
            icons={[
              "calendar",
              "time",
              "star",
              "bell",
              "phone",
              "smile",
              "meh",
              "frown",
            ]}
            activeIcon={icon}
            setActiveIcon={setIcon}
          />

          <ColorField
            colors={[
              "red",
              "green",
              "blue",
              "yellow",
              "orange",
              "purple",
              "teal",
            ]}
            activeColor={color}
            setActiveColor={setColor}
          />

          <Text>Description</Text>
          <Input
            type="text"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={(values as any).description}
          />
        </form>
      )}
    </Formik>
  );
};

export default EditForm;
