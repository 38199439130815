import React from "react";
import { Box, Input, Button, Text } from "@chakra-ui/core";
import { FieldI } from "../../types";

interface FormRenderProps {
  fields: FieldI[];
  backgroundColor: string;
  currentTitle: string;
  currentDescription: string;
  currentOrder: any;
}

export const FormRender: React.FC<FormRenderProps> = ({
  fields,
  backgroundColor,
  currentTitle,
  currentDescription,
  currentOrder,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={10}
      pb={5}
      w="400px"
      bg={backgroundColor}
    >
      <Text textAlign="center" fontSize="3xl" fontWeight="1000">
        {currentTitle}
      </Text>
      <Text textAlign="center" fontSize="2xl" fontWeight="500">
        {currentDescription}
      </Text>

      {fields
        .filter((field, index) => !currentOrder.hidden[index])
        .map((field) => {
          return (
            <Box key={field.id}>
              <Text>{field.name}</Text>
              <Input type="text" />
            </Box>
          );
        })}
      <Button type="submit" mt={3}>
        Submit
      </Button>
    </Box>
  );
};
