import React from "react";

import { AspectRatioBox } from "@chakra-ui/core";
import { OddModal } from "oddular-common";

interface VideoModalProps {
  video: string;
  setClosed: () => void;
}

export const VideoModal: React.FC<VideoModalProps> = ({ video, setClosed }) => {
  return (
    <OddModal
      context="default"
      header="Video"
      children={
        <AspectRatioBox ratio={16 / 9}>
          <iframe title="video" src={video} allowFullScreen />
        </AspectRatioBox>
      }
      footer=""
      isOpen={video !== ""}
      onClose={setClosed}
    />
  );
};

export default VideoModal;
