import { atom } from "recoil";

export interface IProfile {
  id: number;
  username: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  date_joined: string;
  account: string;
  profile_picture: any;
  authenticated: boolean;
  is_staff: boolean;
}

export const userProfileState = atom({
  key: "userProfileState",
  default: {} as IProfile,
});
