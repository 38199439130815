import React, { useState } from "react";
import { Menu, MenuButton, MenuList, Box } from "@chakra-ui/core";
import Draggable from "react-draggable";
import { IColor } from "../../types";
interface ColorPickerProps {
  initialColor: string;
  exportColor: (color: string) => void;
}
export const ColorPicker: React.FC<ColorPickerProps> = ({
  initialColor,
  exportColor,
}) => {
  const fromHex = (hex: string) => {
    if (hex.length >= 7) {
      let r = parseInt(hex.charAt(1), 16) * 16 + parseInt(hex.charAt(2), 16);
      let g = parseInt(hex.charAt(3), 16) * 16 + parseInt(hex.charAt(4), 16);
      let b = parseInt(hex.charAt(5), 16) * 16 + parseInt(hex.charAt(6), 16);
      return { red: r, green: g, blue: b };
    } else if (hex.length >= 4) {
      let r = parseInt(hex.charAt(1), 16);
      let g = parseInt(hex.charAt(2), 16);
      let b = parseInt(hex.charAt(3), 16);
      return { red: r * 17, green: g * 17, blue: b * 17 };
    } else {
      return { red: 0, green: 0, blue: 0 };
    }
  };
  const [color, setColor] = useState(fromHex(initialColor) as IColor);
  const updateColor = (color: IColor) => {
    setColor(color);
    exportColor(toHex(color));
  };
  const toHex = (color: IColor) => {
    let r = color.red.toString(16);
    if (r.length === 1) {
      r = "0" + r;
    }
    let g = color.green.toString(16);
    if (g.length === 1) {
      g = "0" + g;
    }
    let b = color.blue.toString(16);
    if (b.length === 1) {
      b = "0" + b;
    }
    return "#" + r + g + b;
  };
  return (
    <Menu>
      <MenuButton>
        <Box w="30px" h="30px" border="2px solid" bg={toHex(color)} />
      </MenuButton>
      <MenuList bg="gray.100">
        <Box
          w="275px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mx="10px"
        >
          <Box
            w="275px"
            h="23px"
            style={{
              background: `linear-gradient(90deg, rgba(0,${color.green},${color.blue},1) 0%, rgba(255,${color.green},${color.blue},1) 100%)`,
            }}
            my="2px"
          >
            <Draggable
              axis="x"
              defaultPosition={{ x: color.red, y: 0 }}
              bounds={{ left: 0, right: 255 }}
              onDrag={(e, d) => {
                updateColor({ red: d.x, blue: color.blue, green: color.green });
              }}
            >
              <Box
                bg={toHex({ red: color.red, blue: 0, green: 0 })}
                w="20px"
                h="20px"
                my="2px"
                border="1px solid white"
              />
            </Draggable>
          </Box>
          <Box
            w="275px"
            h="23px"
            style={{
              background: `linear-gradient(90deg, rgba(${color.red},0,${color.blue},1) 0%, rgba(${color.red},255,${color.blue},1) 100%)`,
            }}
            my="2px"
          >
            <Draggable
              axis="x"
              defaultPosition={{ x: color.green, y: 0 }}
              bounds={{ left: 0, right: 255 }}
              onDrag={(e, d) => {
                updateColor({ red: color.red, blue: color.blue, green: d.x });
              }}
            >
              <Box
                bg={toHex({ red: 0, blue: 0, green: color.green })}
                w="20px"
                h="20px"
                my="2px"
                border="1px solid white"
              />
            </Draggable>
          </Box>
          <Box
            w="275px"
            h="23px"
            style={{
              background: `linear-gradient(90deg, rgba(${color.red},${color.green},0,1) 0%, rgba(${color.red},${color.green},255,1) 100%)`,
            }}
            my="2px"
          >
            <Draggable
              axis="x"
              defaultPosition={{ x: color.blue, y: 0 }}
              bounds={{ left: 0, right: 255 }}
              onDrag={(e, d) => {
                updateColor({ red: color.red, blue: d.x, green: color.green });
              }}
            >
              <Box
                bg={toHex({ red: 0, blue: color.blue, green: 0 })}
                w="20px"
                h="20px"
                my="2px"
                border="1px solid white"
              />
            </Draggable>
          </Box>
        </Box>
      </MenuList>
    </Menu>
  );
};
