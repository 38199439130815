//@ts-nocheck

import React from "react";
import {
  Input,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Button,
} from "@chakra-ui/core";
import { Formik } from "formik";

import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

import { authenticationState } from "./atom";

interface LoginFormProps {}

export const LoginForm: React.FC<LoginFormProps> = () => {
  const history = useHistory();
  const setAuthentication = useSetRecoilState(authenticationState);

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        requestTokens(values.email, values.password).then((result) => {
          setAuthentication({ ...result, authenticated: true });
          setSubmitting(false);
          history.push("/");
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel htmlFor="email">Email address</FormLabel>
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <FormHelperText id="email-helper-text">
                {errors.email && touched.email && errors.email}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {errors.password && touched.password && errors.password}
            </FormControl>
            <Button type="submit" disabled={isSubmitting} variantColor="blue">
              Submit
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
