import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/core";

interface PageProps {
  sidebar: boolean;
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ sidebar = true, children }) => {
  return (
    <>
      <Flex flex="1" flexDirection="column" minW="920px" position="relative">
        {children}
      </Flex>
    </>
  );
};

export default Page;
