import React, { useState } from "react";
import { FieldI } from "../../features/fluxTable/types";
import { Box } from "@chakra-ui/core";
import { CustomEditable } from "../lib/CustomEditable";
import { flux } from "oddular-common";
import { Cell } from "react-table";

interface EditableCellProps {
  cell: Cell<
    {
      [x: string]: {};
    },
    any
  >;
  field: FieldI;
  recordID: string;
  setVideo: (video: string) => void;
  setImage: (image: string) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  cell,
  field,
  recordID,
  setVideo,
  setImage,
}) => {
  const [currentValue, setCurrentValue] = useState(cell.value);
  const [editing, setEditing] = useState(false);

  const [lastClick, setLastClick] = useState(0);
  const updateRecord = (value: any) => {
    setCurrentValue(value);
    const values = {};
    (values as any)[field.slug] = value;
    flux.record.update(recordID, values);
  };
  const handleClick = () => {
    const currentTime = Date.now();
    if (lastClick + 700 > currentTime) {
      setEditing(true);
    } else {
      setLastClick(Date.now());
    }
  };
  return (
    <Box
      as="td"
      {...cell.getCellProps()}
      px={3}
      py={1}
      border="1px solid black"
      onClick={handleClick}
    >
      <CustomEditable
        value={currentValue}
        setValue={updateRecord}
        editing={editing}
        exitEditing={() => setEditing(false)}
      />
    </Box>
  );
};
