//@ts-nocheck

import React, { useState } from "react";

import { Box, Icon } from "@chakra-ui/core";

import { useTable } from "react-table";
import { ColumnI } from "../../types";

import ImageModal from "../modals/ImageModal";
import VideoModal from "../modals/VideoModal";
import { EditableCell } from "./EditableCell";
import { flux } from "oddular-common";
import { Editable } from "../lib/Editable";

interface GridProps {
  columns: ColumnI[];
  data: any;
  records: IRecord[];
  fields: FieldI[];
}

export const Grid: React.FC<GridProps> = ({
  columns,
  data,
  records,
  fields,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  const renameField = (fieldID: string, name: string) => {
    flux.field.rename(fieldID, name);
  };
  // Render the UI for your table
  return (
    <>
      <ImageModal image={image} setClosed={() => setImage("")} />
      <VideoModal video={video} setClosed={() => setVideo("")} />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <Box as="tr" {...headerGroup.getHeaderGroupProps()} bg="gray.100">
              <Box as="th" px={3} py={1}></Box>
              {headerGroup.headers.map((field) => (
                <Box as="th" {...field.getHeaderProps()} px={3} py={1}>
                  <Box alignItems="center" whiteSpace="nowrap">
                    <Icon
                      mr={2}
                      name={`${
                        fields.find((f: FieldI) => {
                          return f.slug === field.id;
                        })?.field_type
                      }_field`}
                    />
                    <Editable
                      value={field.render("Header")}
                      setValue={(value: string) =>
                        renameField(
                          fields.find((f: FieldI) => {
                            return f.slug === field.id;
                          })?.id,
                          value
                        )
                      }
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Box as="tr" {...row.getRowProps()}>
                <Box as="td" px={3} py={1} border="1px solid black">
                  <Icon
                    name="delete"
                    onClick={() => {
                      flux.record.remove(records[row.index].id);
                    }}
                    mr={2}
                  />
                  {i+1}
                </Box>
                {row.cells.map((cell) => {
                  return (
                    <EditableCell
                      cell={cell}
                      field={fields.find((field: FieldI) => {
                        return field.slug === cell.column.id;
                      })}
                      recordID={records[row.index].id}
                      setVideo={setVideo}
                      setImage={setImage}
                    />
                  );
                })}
              </Box>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Grid;
