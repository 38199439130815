import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { userProfileState } from "./features/userProfile/atom";
import { baseListState } from "./features/fluxBase/atom";

import { useAuthenticated } from "oddular-common";
import { actionListState } from "./features/fluxAction/atom";
import { getProfile, getBaseList, getActionList } from "./api";

interface CallbacksProps {}

export const Callbacks: React.FC<CallbacksProps> = () => {
  const authenticated = useAuthenticated();
  const [, setUserProfile] = useRecoilState(userProfileState);
  const [, setBaseList] = useRecoilState(baseListState);
  const [, setActionList] = useRecoilState(actionListState);

  useEffect(() => {
    if (authenticated) {
      getProfile().then((data) => {
        setUserProfile(data);
      });

      getBaseList().then((data) => {
        setBaseList(data);
      });
      getActionList().then((data) => {
        setActionList(data);
      });
    }
  }, [authenticated, setBaseList, setUserProfile, setActionList]);

  return null;
};

export default Callbacks;
