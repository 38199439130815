import React from "react";
import { Link, PseudoBox, Text, useTheme, Icon } from "@chakra-ui/core";
import { IAction } from "../../types";

interface ActionCardProps {
  action: IAction;
}

export const ActionCard: React.FC<ActionCardProps> = ({ action }) => {
  const theme = useTheme();
  return (
    <Link href={`/action/${action.id}`}>
      <PseudoBox
        padding={6}
        boxShadow="sm"
        borderRadius="1rem"
        transition="all .15s ease-in-out"
        borderWidth={3}
        bg={`${action.meta.color}.400`}
        color="white"
        borderColor={`${action.meta.color}.300`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        _hover={{
          boxShadow: theme.shadows.md,
          transform: "translate(0, -1px)",
        }}
      >
        <Icon name={action.meta.icon} fontSize="4xl" my={2} />
        <Text fontSize="lg" fontWeight="700" my={2}>
          {action.name}
        </Text>
        <Text fontSize="sm" fontWeight="400" my={2}>
          {action.description}
        </Text>
      </PseudoBox>
    </Link>
  );
};

export default ActionCard;
