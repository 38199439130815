import React, { useRef } from "react";
import EditForm from "../fluxTable/EditForm";
import { OddModal } from "oddular-common";
import { Button } from "@chakra-ui/core";

interface EditModalProps {
  tableID: string;
  isOpen: boolean;
  onClose: () => void;
}

export const EditModal: React.FC<EditModalProps> = ({
  tableID,
  isOpen,
  onClose,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleFormSubmit = () => {
    if (formRef !== null) {
      formRef.current!.handleSubmit();
    }
  };
  return (
    <OddModal
      context="default"
      header="Edit Table"
      children={
        <EditForm tableID={tableID} formRef={formRef} afterSubmit={onClose} />
      }
      footer={[
        <Button key={0} size="sm" variantColor="gray" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={1}
          size="sm"
          onClick={handleFormSubmit}
          variantColor="blue"
        >
          Save
        </Button>,
      ]}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default EditModal;
