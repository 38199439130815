import React from "react";
import { Tab, PseudoBoxProps, Box } from "@chakra-ui/core";
import { FieldI, ColumnI, IRecord } from "../../types";

import CardGrid from "../../components/lib/Grid";
type TabProps = PseudoBoxProps & React.ButtonHTMLAttributes<any>;

interface CardTabPanelProps {
  columns: ColumnI[];
  data: any;
  fields: FieldI[];
  records: IRecord[];
}

const CardTab: React.ForwardRefRenderFunction<React.PropsWithChildren<
  TabProps
>> = (props, ref) => {
  return (
    <Tab
      borderWidth="1px"
      rounded="md"
      m={3}
      _selected={{
        borderColor: "gray.500",
        bg: "gray.100",
      }}
      ref={ref}
      {...props}
    >
      Cards
    </Tab>
  );
};
const CardTabPanel: React.FC<CardTabPanelProps> = ({
  columns,
  data,
  fields,
  records,
}) => {
  return (
    <Box w="full" height="600px" overflow="scroll">
      <CardGrid data={data} fields={fields} records={records} />
    </Box>
  );
};

export default {
  Tab: React.forwardRef(CardTab),
  Panel: CardTabPanel,
};
