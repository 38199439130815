import React, { useState, useEffect } from "react";

import { Text, Button, Input, Box } from "@chakra-ui/core";

import { Formik } from "formik";
import { getField, deleteField } from "../../api";
import { FieldI } from "../../types";

interface DeleteFormProps {
  fieldID: string;
  onRefreshTable?: () => void;
}

export const DeleteForm: React.FC<DeleteFormProps> = ({
  fieldID,
  onRefreshTable,
}) => {
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    if (!!fieldID) {
      getField(fieldID).then((result: FieldI) => {
        setInitialValues({
          name: result.name,
          slug: result.slug,
        });
      });
    }
  }, [fieldID]);
  return (
    <Formik
      initialValues={{ name: "" }}
      validate={(values) => {
        const errors = {};
        if ((values as any).name !== (initialValues as any).name) {
          (errors as any).name = "Needs to match field name";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        deleteField(fieldID).then((data) => {
          !!onRefreshTable && onRefreshTable();
          setSubmitting(false);
        });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Text>Write the text below to confirm deletion</Text>
          <Text
            userSelect="none"
            as="kbd"
            borderWidth="2px"
            borderColor="red.500"
            fontWeight="800"
            p={1}
            ml={4}
            fontFamily="mono"
          >
            {(initialValues as any).name}
          </Text>
          <Input
            m={2}
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button type="submit" variantColor="red" m={3}>
              Delete
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default DeleteForm;
