import React from "react";
import { Link, PseudoBox, Text, useTheme, Icon } from "@chakra-ui/core";
import { BaseI } from "../../types";

interface BaseCardProps {
  base: BaseI;
}

export const BaseCard: React.FC<BaseCardProps> = ({ base }) => {
  const theme = useTheme();
  return (
    <Link href={`/base/${base.id}`}>
      <PseudoBox
        padding={6}
        boxShadow="sm"
        borderRadius="1rem"
        transition="all .15s ease-in-out"
        borderWidth={3}
        bg={`${base.meta.color}.400`}
        color="white"
        borderColor={`${base.meta.color}.300`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        _hover={{
          boxShadow: theme.shadows.md,
          transform: "translate(0, -1px)",
        }}
      >
        <Icon name={base.meta.icon} fontSize="4xl" my={2} />
        <Text fontSize="lg" fontWeight="700" my={2}>
          {base.name}
        </Text>
        <Text fontSize="sm" fontWeight="400" my={2}>
          {base.meta.description}
        </Text>
      </PseudoBox>
    </Link>
  );
};

export default BaseCard;
