import React from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/core';

import LoginForm from '../features/authentication/LoginForm';

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
  return (
    <Flex
      w="full"
      h="auto"
      flex="1"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bg="gray.100"
    >
      <Text as="h1" fontSize="xl" fontWeight="700">
        Welcome to Flux
      </Text>

        <Text as="h2" fontSize="xs" fontWeight="400" overflow="hidden">
          Login using your
        </Text>
        <Image src="./oddID.png" height="22px" width="auto" mb={1}/>


      <Box bg="white" borderRadius="lg" p={10}>
        <LoginForm />
      </Box>
    </Flex>
  );
};

export default LoginPage;
