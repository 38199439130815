import React, { useEffect, useState } from "react";

import { useParams, Link } from "react-router-dom";

import {
  Icon,
  Flex,
  Box,
  Text,
  Menu,
  Button,
  MenuList,
  MenuItem,
  SimpleGrid,
} from "@chakra-ui/core";

import { TableI, BaseI } from "../types";

import { EditModal } from "../features/fluxBase/EditModal";
import { DeleteModal } from "../features/fluxBase/DeleteModal";
import { CreateModal } from "../features/fluxTable/CreateModal";
import { TableCard } from "../components/lib/TableCard";
import { EllipsisMenuButton } from "../components/menu/EllipsisMenuButton";
import { getTablesList, getBase } from "../api";

interface FluxBasePageProps {}

const FluxBasePage: React.FC<FluxBasePageProps> = () => {
  let { id } = useParams();
  const [tables, setTables] = useState([] as TableI[]);
  const [base, setBase] = useState({} as BaseI);
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);

  const refreshPage = (id: string) => {
    if (!!id) {
      getTablesList(id).then((data) => {
        setTables(data);
      });
      getBase(id).then((data) => {
        setBase(data);
      });
    }
  };
  useEffect(() => {
    refreshPage(id);
  }, [id]);
  if (Object.keys(base).length === 0) {
    return null;
  }
  return (
    <Box borderWidth="2px" borderColor={`${base.meta.color}.500`} p={3} m={3}>
      <CreateModal
        baseID={id}
        isOpen={create}
        onClose={() => {
          setCreate(false);
          refreshPage(id);
        }}
      />
      <EditModal
        baseID={id}
        isOpen={edit}
        onClose={() => {
          setEdit(false);
          refreshPage(id);
        }}
      />
      <DeleteModal
        baseID={id}
        isOpen={del}
        onClose={() => {
          setDel(false);
          refreshPage(id);
          window.location.replace("/");
        }}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link to="/">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="arrow-back" size="25px" m={3} />
            <Text fontSize="lg">Bases</Text>
          </Box>
        </Link>
        <Menu>
          <EllipsisMenuButton />
          <MenuList>
            <MenuItem>
              <Button onClick={() => setCreate(true)} variant="ghost">
                <Icon name="add" m={2} color="green.500" />
                <Text color="green.500">Create Table</Text>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button onClick={() => setEdit(true)} variant="ghost">
                <Icon name="edit" m={2} color="blue.500" />
                <Text color="blue.500">Edit Base</Text>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button onClick={() => setDel(true)} variant="ghost">
                <Icon name="delete" m={2} color="red.500" />
                <Text color="red.500">Delete Base</Text>
              </Button>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        px={3}
      >
        <Text fontSize="3xl" fontWeight="1000">
          {base.name}
        </Text>
      </Box>
      {tables.length > 0 ? (
        <Flex flexDirection={{ base: "column", md: "row" }}>
          <SimpleGrid columns={{ base: 2, lg: 4 }} spacing={10}>
            {tables.map((table: TableI) => {
              return (
                <Box key={table.id}>
                  <Link to={`/table/${table.id}`}>
                    <TableCard table={table} />
                  </Link>
                </Box>
              );
            })}
          </SimpleGrid>
        </Flex>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          w="full"
          py={20}
        >
          <Icon name="frown" w="5rem" h="5rem" />
          <Text fontSize="3xl" fontWeight="500" p={3}>
            This base has no tables.
          </Text>
          <Button onClick={() => setCreate(true)} p={3} variantColor="blue">
            Create Table
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FluxBasePage;
