import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { CSSReset, ThemeProvider } from "@chakra-ui/core";
import Router from "./Router";

import { RecoilRoot } from "recoil";
import { theme, OddularProvider } from "oddular-common";
import Callbacks from "./Callbacks";

const initializeState = ({ set }: { set: any }) => {};

ReactDOM.render(
  <React.StrictMode>
    <OddularProvider app="flux">
      <RecoilRoot initializeState={initializeState}>
        <Callbacks />
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CSSReset />
            <Router />
          </ThemeProvider>
        </Provider>
      </RecoilRoot>
    </OddularProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
