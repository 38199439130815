import React, { useState } from "react";

import {
  Box,
  Text,
  Button,
  Icon,
  Link,
  Menu,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";
import Grid from "./Table";
import { ColumnI, FieldI } from "../../types";
import CreateRecordModal from "../modals/CreateRecordModal";
import { EditModal } from "../../features/fluxTable/EditButton";
import { DeleteModal } from "../../features/fluxTable/DeleteModal";
import { EllipsisMenuButton } from "../menu/EllipsisMenuButton";
import { getRecords } from "../../api";

interface DisplayTablePageProps {
  name: string;
  fields: FieldI[];
  id: string;
  color: string;
  base: string;
  onRefreshTable?: () => void;
}

const DisplayTable: React.FC<DisplayTablePageProps> = ({
  name,
  fields,
  id,
  color,
  base,
  onRefreshTable,
}) => {
  const columns = fields.map((field: FieldI) => {
    return { Header: field.name, accessor: field.slug } as ColumnI;
  });

  const [initialData] = useState({});
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);

  const refreshRecords = () => {
    getRecords(id).then((data: any) => {
      setRecords(data);
      setData(data.map((d: any) => d.values));
    });
  };

  return (
    <Box key={id} borderColor={`${color}.500`} borderWidth={2} p={3} m={3}>
      <CreateRecordModal
        tableID={id}
        isOpen={create}
        baseID={base}
        onClose={() => {
          refreshRecords();
          setCreate(false);
        }}
        initialData={initialData}
        fields={fields}
      />
      <EditModal
        tableID={id}
        isOpen={edit}
        onClose={() => {
          setEdit(false);
          !!onRefreshTable && onRefreshTable();
        }}
      />
      <DeleteModal
        tableID={id}
        baseID={base}
        isOpen={del}
        onClose={() => {
          window.location.replace("/base/" + base);
          setDel(false);
        }}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link href={`/base/${base}`}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="arrow-back" size="25px" m={3} />
            <Text>Back to Tables</Text>
          </Box>
        </Link>
        <Menu>
          <EllipsisMenuButton />
          <MenuList>
            <MenuItem>
              <Button onClick={() => setCreate(true)} variant="ghost">
                <Icon name="add" m={2} color="green.500" />
                <Text color="green.500">New Record</Text>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button onClick={() => setEdit(true)} variant="ghost">
                <Icon name="edit" m={2} color="blue.500" />
                <Text color="blue.500">Edit Table</Text>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button onClick={() => setDel(true)} variant="ghost">
                <Icon name="delete" m={2} color="red.500" />
                <Text color="red.500">Delete Table</Text>
              </Button>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        px={3}
        alignItems="center"
      >
        <Text fontSize="3xl" fontWeight="1000">
          {name}
        </Text>
      </Box>
      <Grid
        columns={columns}
        tableID={id}
        baseID={base}
        fields={fields}
        onRefreshTable={onRefreshTable}
        data={data}
        records={records}
        refreshRecords={refreshRecords}
      />
    </Box>
  );
};

export default DisplayTable;
