import { atom, selector } from 'recoil';


export const authenticationState = atom({
         key: 'authenticationState',
         default: {
           access: sessionStorage.getItem('__odd_access') || '',
           refresh: localStorage.getItem('__odd_refresh') || '',
           authenticated: false,
         },
       });

export const isAuthenticatedState = selector({
  key: 'isAuthenticatedState',
  get: ({ get }) => {
    const authentication = get(authenticationState);
    return authentication.access !== '';
  },
});

export default authenticationState;

