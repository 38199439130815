import React, { useEffect } from "react";
import { user } from "oddular-common";
import {
  Avatar,
  AvatarBadge,
  Text,
  PseudoBox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";

import { useRecoilState } from "recoil";
import { userProfileState } from "../../userProfile/atom";

interface UserMenuProps {}

const UserMenu: React.FC<UserMenuProps> = () => {
  const [userProfile] = useRecoilState(userProfileState);
  useEffect(() => {}, [userProfile]);

  return (
    <Menu>
      {/* Ignore because of as=""
      // @ts-ignore */}
      <MenuButton
        display="flex"
        flexDirection="row"
        alignItems="center"
        bg="gray.100"
        px={2}
        py={1}
        borderRadius="10rem"
      >
        <Text fontWeight="700">{userProfile.username}</Text>
        <Avatar size="2xs" ml={2}>
          <AvatarBadge size="0.5rem" bg="green.500" />
        </Avatar>
      </MenuButton>
      <MenuList placement="bottom-end">
        <PseudoBox
          as={MenuItem}
          _hover={{ bg: "gray.400", color: "white" }}
          cursor="default"
          minH="28px"
        >
          Settings
        </PseudoBox>
        <PseudoBox
          as={MenuItem}
          _hover={{ bg: "gray.400", color: "white" }}
          cursor="default"
          minH="28px"
          onClick={user.logout}
        >
          Logout
        </PseudoBox>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
