import { atom } from "recoil";
import { DjangoViewset, IAction } from "../../types";

export const actionListState = atom({
  key: "actionListState",
  default: {
    count: 0,
    page_size: 25,
    last_page: 1,
    page_num: 1,
    num_pages: 1,
    next: null,
    previous: null,
    results: [] as IAction[],
  } as DjangoViewset,
});
