import React from "react";
import {
  Flex,
  Menu,
  Button,
  MenuButton,
  Icon,
  MenuList,
  Text,
} from "@chakra-ui/core";

interface OddNavMenuProps {
  menuItems: React.ReactFragment | React.ReactChild;
  title: string | React.ReactChild;
}

export const OddNavMenu: React.FC<OddNavMenuProps> = ({ menuItems, title }) => {
  return (
    <Flex
      flexDirection="row"
      bg="gray.100"
      borderRadius="10rem"
      alignItems="center"
    >
      <Menu>
        <Button variant="ghost" borderRadius="10rem" as={MenuButton}>
          <Icon
            borderRadius="10rem"
            bg="whiteAlpha.500"
            m={1}
            name="chevron-down"
          />
          <Text m={1} fontWeight="700" color="black" fontSize="lg">
            {title}
          </Text>
        </Button>
        <MenuList placement="bottom-start">{menuItems}</MenuList>
      </Menu>
    </Flex>
  );
};
