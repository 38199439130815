import React from "react";

import { Image } from "@chakra-ui/core";
import { OddModal } from "oddular-common";

interface ImageModalProps {
  image: string;
  setClosed: () => void;
}

export const ImageModal: React.FC<ImageModalProps> = ({ image, setClosed }) => {
  return (
    <OddModal
      context="default"
      header="Image"
      children={<Image src={image} alt="Image" />}
      footer=""
      isOpen={image !== ""}
      onClose={setClosed}
    />
  );
};

export default ImageModal;
