import React from 'react';

import Page from '../features/page/Page';

interface ProductPageProps {}

const ProductPage: React.FC<ProductPageProps> = () => {
  return (
    <Page sidebar={true}>
      <h1>Product Page</h1>
    </Page>
  );
};

export default ProductPage;
