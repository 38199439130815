import React, { useState, useEffect } from "react";

import { Text, Button, Input, Box } from "@chakra-ui/core";

import { Formik } from "formik";
import { getField, editField } from "../../api";
import { FieldI } from "../../types";

interface EditFormProps {
  fieldID: string;
  onRefreshTable?: () => void;
}

export const EditForm: React.FC<EditFormProps> = ({
  fieldID,
  onRefreshTable,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!!fieldID) {
      getField(fieldID).then((data: FieldI) => {
        setInitialValues({
          name: data.name,
          slug: data.slug,
        });
        setReady(true);
      });
    }
  }, [fieldID, setReady]);
  if (!ready) {
    return null;
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if ((values as any).name === "") {
          (errors as any).name = "Required";
        }
        if ((values as any).slug === "") {
          (errors as any).slug = "Required";
        }
        return {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        editField(fieldID, values).then((data) => {
          !!onRefreshTable && onRefreshTable();
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Text>Name</Text>
          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={(values as any).name}
            value={(values as any).name}
          />
          <Text>Slug</Text>
          <Input
            type="text"
            name="slug"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={(values as any).slug}
            value={(values as any).slug}
          />
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button type="submit" variantColor="orange" m={3}>
              Edit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EditForm;
